module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://flamingofinancial.co.nz"},
    },{
      plugin: require('../node_modules/gatsby-theme-jules/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteUrl":"https://flamingofinancial.co.nz","title":"Flamingo Financial New Zealand ","description":"At Flamingo Financial, we would like to help you through all of life's financial milestones and be your financial advisor for life","author":"@power_board"},"datocms":{"apiToken":"ecfee959962666f18c2c39d61ea0e4","previewMode":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flamingo Financial","short_name":"Flamingo","start_url":"/","background_color":"#f7f0eb","theme_color":"#BEAF87","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f532af70c0f62f44c8a08303fd85b6ae"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
